<template>
	<div id="login_init">66
	</div>
</template>

<script type="text/javascript">
	import pcj from '@/assets/js/aes_encrypt.js'
	import {
		getCurrentInstance,
		defineComponent,
		ref,
		reactive
	} from 'vue'
	export default defineComponent({
		name: 'LoginSso',
		setup() {
			// 访问 globalProperties
			const ctx = getCurrentInstance().appContext.config.globalProperties

			// 获取当前路由含带的参数信息
			const thisRouteParam = ctx.$route.query

			ctx.$loading({
				lock: true,
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.8)'
			})

			if (thisRouteParam.encryption) {
				// 请求单点登录
				ctx.$request_nl_.post(ctx.$api_.state.Home.Login.login_inside_auth.url, {
						encryption: thisRouteParam.encryption
					})
					.then((respon) => {
						if (respon.Code === ctx.$code_.state.success) {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'success'
							})
							
							setTimeout(()=>{
								window.location.href=respon.Data.redirect_url
							},1500)
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'warning'
							})
							
							ctx.$router.push({
								name: 'SignIn'
							})
						}
					})
			} else {
				ctx.$notify({
					title: '温馨提示',
					message: '登录错误！',
					type: 'warning'
				})

				ctx.$router.push({
					name: 'SignIn'
				})
			}
		}
	})
</script>
